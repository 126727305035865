.cardTramits {
  background-color: #212529 !important;
  border: none !important;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.25), 0 3px 20px 11px rgba(0, 0, 0, 0) !important;
  margin: 12px !important;
  border-radius: 1.25rem !important;
  color: white !important;
  font-weight: 900 !important;
  /* text-transform: uppercase; */
  height: 200px !important;
}

.cardTramits:hover .imatgeTramits {
  opacity: 0.55 !important;
  color: white !important;
}

.cardTramits:hover .card-title {
  color: white;
}

.cardTramits .card-title {
  font-size: 26px !important;
  font-weight: revert !important;
}

.imatgeTramits {
  opacity: 0.8 !important;
  border-radius: 1.25rem !important;
  height: 200px;
}

.react-multi-carousel-dot-list {
  position: initial !important;
  padding-bottom: 0.25rem !important;
}

.react-multi-carousel-dot--active button {
  background: grey !important;
}

