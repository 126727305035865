.header {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  background-color: transparent !important;
  z-index: 2000;
}

.header .nav-item{
  margin-right: 0.5rem !important;
}

.header .especialMarge{
  margin-right: 0 !important;
}

.navbar-brand {
  padding: 0 !important;
}

.navHeader {
  max-width: 430px !important;
}

.nav .nav-link {
  color: #495057 !important;
  font-size: 16px;
  padding-right: 0px;
  padding-left: 6px;

}

.nav .nav-link.active {
  color: #9e003c !important;
}

.nav .nav-link:hover {
  text-decoration: underline;
  color: #9e003c !important;
}

.seuElectronica {
  color: white;
  background-color: rgb(158, 0, 60, 100%);

  text-decoration: none !important;
  text-transform: uppercase;

  border-radius: 12px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;

  font-size: 1rem;
  font-weight: bold;

}

.navSeu{
  align-self: center !important;
}

.seuElectronica:hover {
  background-color: #c1205e !important;
  color: white;
}

.links {
  background-color: white !important;
  border: none !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.navPortals:hover {
  color: #9e003c !important;
  text-decoration: underline !important;
}

.navPortals {
  color: #9e003c !important;
  text-decoration: none !important;
  background-color: rgb(0, 0, 0, 5%);
  border-radius: 12px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 10px;
  padding-right: 10px;
}

#txt {
  /* background-color: rgb(0, 0, 0, 5%); */
  border-radius: 12px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 10px;
  padding-right: 10px;

  font-size: 1.1rem;
  color: #444444 !important;
  font-weight: normal;

}

.time{
  font-size: 1.5rem;
}

#txt:hover {
  text-decoration: none !important;
}

@media (max-width: 960px) {
  .navHeader {
    max-width: 100% !important;
  }
}

@media (max-width: 545px) {
  .navHeader {
    margin-top: 25px !important;
  }
}

@media (max-width: 398px) {
  .linkPortal {
    margin-bottom: 5px !important;
  }
}
