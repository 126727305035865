footer {
    margin-top: 200px !important;
  }
  
  .navbar-brand {
    color: #6c757d;
  }
  
  .logoLink {
    text-decoration: none !important;
  }
  
  .linksDiv {
    float: right;
    margin-top: -40px;
    margin-right: 1%;
  }
  
  .linksWeb {
    color: #9e003c !important;
    text-decoration: none;
  }
  
  .list-group .active {
    background-color: white !important;
    color: #9e003c !important;
    border-color: #9e003c;
  }
  
  .linksMenu {
    color: black !important;
    text-decoration: none;
  }
  .list-group-item {
    padding: 16px;
  }
  
  .list-group-item:hover {
    background-color: #caced1 !important;
  }
  
  .floatMenu {
    float: right !important;
  }
  
  .carouselInici {
    height: 700px !important;
  }
  
  .textGris {
    color: black;
  }
  
  .contendorLinks{
    max-width: fit-content !important;
    align-content: center !important;
    align-items: center !important;
  }