.labelProcediment {
  font-size: 18px;
  color: #444444;
  font-weight: bold;
  /* text-transform: uppercase; */
}

.textProcediment {
  background-color: rgb(0, 0, 0, 5%);
  border-radius: 12px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  max-width: fit-content;
  font-size: 1rem;
  color: #212529;
  font-weight: normal;
}

.titolTramit {
  font-weight: bold;
}

.linkDocumentPD{
  color: #9e003c !important;
  text-decoration: none;
  border: none;
}


.linkDocumentPD:hover {
  color: #696969 !important;
  text-decoration: underline;
}
