.procedimentLink {
  font-size: 16px !important;
  color: #9e003c !important;
  text-decoration: none !important;
}

.procedimentLink:hover {
  text-decoration: underline !important;
}

.procedimentFamilia {
  font-size: 14px !important;
  color: #444444;
  margin-left: 28px;
}

.cardResultat {
  text-align:  left !important;
  border: none !important;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.3) !important; */
  border-radius: 0px !important;
}

.cardResultat:hover {
  background-color: rgb(0, 0, 0, 5%) !important;
}
