
.callout {
    padding: 20px 50px 20px 20px;
    margin: 20px 0;
    border: 1px solid #eee;
    border-left-width: 5px;
    border-radius: 3px;
    border-left-color: #9e003c !important;
    max-width: fit-content;
  
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.25), 0 3px 20px 11px rgba(0, 0, 0, 0) !important;
  }
  
  .cardMenu {
    height: 55px !important;
    width: 220px !important;
    border: none;
    text-align: center;
  }
  
  .imatgeMenu {
    height: 55px !important;
    width: 220px !important;
    opacity: 0.55;
  }
  
  .cardMenu .card-title {
    font-size: 18px;
    font-weight: revert;
  }


  .linkDocument {
    color: #9e003c !important;
    text-decoration: none;
    border: none;
    background-color: white;
  }
  
  .linkDocument:hover {
    color: #696969 !important;
    text-decoration: underline;
  }