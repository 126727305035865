.temps {
  display: flex;
  justify-content: center;
}

.dataHora {
  background-color: rgb(0, 0, 0, 5%);
  border-radius: 12px;
  padding-top: 10px;
  padding-bottom: 10px;

  align-self: center !important;
  font-size: 1rem;
  color: #9e003c !important;
  font-weight: normal;
  width: 50% !important;
  margin-top: 15px !important;
  margin-bottom: 8px !important;
  font-size: 2rem;
}
