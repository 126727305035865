.linkInicis {
  color: #9e003c !important;
  text-decoration: none !important;
}

.breadcrumb-item.active a {
  color: black !important;
}

.breadcrumb-item.active {
  color: #212529 !important;
}

.bread {
  padding-top: 14px !important;
  padding-left: 20px !important;
  margin-left: 10px;
  font-size: 14px;
}

.bread a {
  color: #9e003c !important;
}

.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #9e003c !important;
  content: var(--bs-breadcrumb-divider, "/");
  --bs-breadcrumb-divider: ">";
}

ol {
  margin-bottom: 0 !important;
}
