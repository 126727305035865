.nav2 {
  background-color: #9e003c;
  color: white;
  width: 100% !important;
  height: 56px !important;
}
.nav2 .nav-link {
  color: white;
}

.navbar-toggler i {
  color: white !important;
}


.navbar-nav .nav-item {
  padding-right: 60px;
  padding-left: 60px;
  padding-top: 8px;
  padding-bottom: 8px;
  font-weight: bolder;
  /* margin-bottom: 2px; */
}

.navbar {
  padding: 0 !important;
}

.navbar-nav .nav-item:hover {
  background-color: #c1205e !important;
}

.nav .nav-link {
  color: #495057 !important;
  font-size: 16px;
  padding-right: 0px;
  padding-left: 6px;
}

.nav .nav-link.active {
  color: #9e003c !important;
}

.nav .nav-link:hover {
  text-decoration: underline;
}

.dropdown-menu {
  margin-top: 7px !important;
  background-color: white;
  color: black;
  width: 80vw !important;
  margin-left: -60px !important;

  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.25), 0 3px 20px 11px rgba(0, 0, 0, 0) !important;
}

.cercador {
  background-color: white !important;
  border-radius: 0.25rem;
}

.btnCercador {
  border: none !important;
  background-color: white !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.btnCercador:hover {
  color: black !important;
}

.cercar {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.dropdown-menu {
  font-size: 16px;
  border-radius: 0px !important;
}
.dropdown-item {
  padding-top: 11px !important;
  padding-bottom: 11px !important;

}

.dropdown-item:hover {
  background-color: #eeeeee !important;
  color: #9e003c !important;
}

.nav-link:hover,
.nav-link:focus {
  color: white !important;
}

.cercar:focus {
  color: #212529;
  background-color: #fff;
  border-color: #9e003c !important;
  box-shadow: 0 0 0 0.25rem rgb(158 0 60 / 50%) !important;
}

.loadingNav {
  min-height: 56px !important;
}

.linkfitxa {
  color: #9e003c !important;
  text-decoration: none;
}

.linkfitxa:hover {
  color: #696969 !important;
  text-decoration: underline;
}

@media (max-width: 1199px) {
  .cercador {
    margin-left: 12px;
  }
  .paddingContainer {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

@media (max-width: 991px) {
  .dropdown-menu {
    margin-left: 0 !important;
  }

  .nav2 {
    height: fit-content !important;
  }

  .navbar-nav .nav-item {
    padding-right: 5px !important;
    padding-left: 5px !important;
  }
  .cercar {
    margin-bottom: 20px !important;
  }
  .btnCercador {
    margin-bottom: 20px !important;
  }
}
