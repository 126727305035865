.page-item.active .page-link {
    color: #fff !important;
    background-color: #dee2e6 !important;
    border-color:#dee2e6  !important;
    color: #9e003c !important;
 
}

.page-link{
  
    color: #444444 !important;
}

.page-link:focus{
    box-shadow:0 0 0 0.25rem rgb(158 0 60 / 25%) !important;  
}