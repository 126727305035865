.titol {
  color: #444444 !important;
  text-align: center !important;
  font-size: 24px !important;
}

.textWeb {
  color: #444444;
  /* text-align: center; */
  font-size: 18px !important;
  padding-left: 5% !important;
  padding-right: 5% !important;
}

.fotoFitxa {
  text-align: center !important;
  align-items: center !important;
}

.marginSpinner {
  margin-top: 300px !important;
}

.errorContainer {
  margin-top: 200px !important;
}

.containerFitxa {
  padding-left: 50px !important;
  padding-right: 100px !important;
}

.text{
  color: #212529;
}

.subtitolRecerca{
  color: #444444 !important;
  font-size: 20px !important;
}

.noResultatsCercaPrincipal{
  color: #444444 !important;
  font-style: italic;
}

.justifyText{
  text-align: justify !important;
}