.sub-titol-cercador {
  font-size: 18px;
  color: #444444 !important;
  font-weight: 600;
}

.btnRecerca {
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 0px !important;
}

.form-check-input:checked {
  background-color: #9e003c !important;
  border-color: #9e003c !important;
}

.form-check-input:focus {
  box-shadow: 0 0 0 0.25rem rgb(158 0 60 / 50%) !important;
  border-color: #9e003c !important;
}

.form-check-label {
  color: #444444 !important;
}

.cercarProcediment:focus {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  box-shadow: none !important;
  border-right: none !important;
  border-color: #9e003c !important;
}

.cercarProcediment {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

#selector:focus {
  color: #212529;
  background-color: #fff;
  border-color: #9e003c !important;
  box-shadow: 0 0 0 0.25rem rgb(158 0 60 / 50%) !important;
}


.noResultats{
  text-align: center !important;
  font-size: 16px !important;
  margin-top: 25px !important;
  font-style: italic;
}